
import { Options, Vue } from 'vue-class-component';
import chatComponent from './chatComponent.vue';

@Options({
    components: {
        chatComponent
    }
})
export default class chatDetail extends Vue {

}
