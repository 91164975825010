import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-5" }
const _hoisted_2 = { class: "page_padding flex_spacebetween_centered" }
const _hoisted_3 = { class: "chatDetail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_chat_component = _resolveComponent("chat-component")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        class: "fw_600",
        type: "h3",
        localizedKey: "backoffice_dettaglioChat",
        text: "Dettaglio chat"
      })
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_chat_component, {
        chatRoomIdentifier: _ctx.$route.params.identifier,
        readonly: true
      }, null, 8, ["chatRoomIdentifier"])
    ])
  ]))
}