
import { Options, Vue } from 'vue-class-component';
import { AppUserClient, ChatClient, NotificationClient } from '@/services/Services';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class chatComponent extends Vue {

    @Prop() chatRoomIdentifier: string;
    @Prop() readonly: boolean;
    
    chatRoom: OM.ChatRoomVM = null;
    chatMessages: OM.ChatMessage[] = [];
    etaUtente: number = 0;

    file: File = null;
    messageText: string = "";
    messageInputKey: number = 0;
    chatWriter: HTMLElement;

    style: string = "";
    
    created(){
        this.init();
    }

    init() {
        this.chatMessages = [];
        this.chatRoom = null;

        var chatRoomId = this.chatRoomIdentifier;

        Promise.all([
            ChatClient.getChatRoom(chatRoomId),
            ChatClient.getLast50Messages(chatRoomId, 0),
        ])
        .then(xs => {
            this.chatRoom = xs[0];
            this.chatMessages = xs[1];
            AppUserClient.getUserAge(this.chatRoom.sender.appUserIdentifier).then(x => this.etaUtente = x);
        })
        .finally( () => {
            this.$nextTick( () => {
                this.scrollToBottom();
            })
        })
    }

    scrollToBottom() {
        var style = "height: calc(100vh - 60px - ";

        var writerContainer = this.$refs.writerContainer as HTMLDivElement;
        style += writerContainer.clientHeight + "px" + ")";

        this.style = style;

        this.$nextTick( () => {
            var scroller = this.$refs.chatScroller as any;
            var chatMessages = document.getElementsByClassName("chatMessages")[0];
            scroller.scrollTo(0, chatMessages.clientHeight);
        })
    }

    onMessageReceive(val: string, isEndMessage: boolean) {
        this.setNewMessage(val, isEndMessage, "", (new Date()).toISOString());
    }

    onMessageUpdate(messageIdentifier: string){
        ChatClient.getMessage(messageIdentifier)
        .then(x => {
            let index = this.chatMessages.findIndex(x => x.identifier == messageIdentifier);
            if(index == -1){
                this.chatMessages.push(x);
            } else {
                this.chatMessages[index] = x;
            }
        })
    }

    setNewMessage(text: string, isEndMessage: boolean, senderId: string, createdOn: string) {
        var newMessageReceived = new OM.ChatMessage();
        newMessageReceived.chatRoomIdentifier = this.chatRoom.chatRoomIdentifier;
        newMessageReceived.text = text;
        newMessageReceived.senderIdentifier = senderId;
        newMessageReceived.isEndMessage = isEndMessage;
        newMessageReceived.createdOn = createdOn;
        this.chatMessages.push(newMessageReceived);
    }

    get isDisabled(){
        return !this.messageText.trim();
    }

    senderIsMe(message: OM.ChatMessage) {
        if(message.senderIdentifier == this.chatRoom.sender.appUserIdentifier)
            return true;
        else
            return false;
    }

    getSender(message: OM.ChatMessage){
        return this.chatRoom.participants.find(x => x.appUserIdentifier == message.senderIdentifier).profileName;
    }

    get hasSentDomanda(){
        return this.chatMessages.length > 0;
    }

    get canSendDomanda(){
        return this.chatRoom.remainingRompighiaccio > 0 &&
            this.chatMessages.filter(x => x.senderIdentifier == store.state.loggedUser.identifier && x.isDomandaRompighiacchio).length < 2;
    }

    get canSeeDomandaButton(){
        return this.chatMessages.length == 0 || this.chatMessages[this.chatMessages.length - 1].isDomandaRompighiacchio == true;
    }

    get hasChatEnded(){
        return this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1].isEndMessage == true;
    }

    get lastIsDomanda(){
        return this.chatMessages.length > 0 && this.chatMessages[this.chatMessages.length - 1].isDomandaRompighiacchio == true;
    }

}
